import React, {forwardRef, ReactNode, useId} from "react";

interface InputProps {
    onChange?: React.FormEventHandler<HTMLInputElement>,
    onBlur?: React.FormEventHandler<HTMLInputElement>,
    defaultValue?: string,
    className?: string,
    required?: boolean,
    value?: string,
    name?: string,
    label?: string,
    autocomplete?: 'off' | undefined
    type?: 'text' | 'email' | 'password' | 'tel' | 'date'
    onClick?: any,
    icon?: ReactNode
    error?: any
}

//TODO aria-invalid={errors.firstName ? "true" : "false"}

const Input = forwardRef<HTMLInputElement, InputProps>(({
                                                            autocomplete,
                                                            icon,
                                                            className,
                                                            defaultValue,
                                                            required,
                                                            onBlur,
                                                            onChange,
                                                            name,
                                                            type,
                                                            label,
                                                            onClick,
                                                            value,
                                                            error

                                                        }, ref) => {

    const id = useId();

    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const blur = (e : any) => {
       if(onBlur) {
           onBlur(e);
       }
        //setShowPassword(false);
    }

    return (
        <div className={`relative flex ${className}`}>
            <input
                autoComplete={autocomplete}
                role={'presentation'}
                id={`${id}-input`}
                name={`${name}`} defaultValue={defaultValue} value={value} ref={ref} onChange={onChange}
                required={required}
                onBlur={blur} type={showPassword ? 'text' : type}
                className={`rounded-md border border-input-dark bg-input h-[39px] block px-2.5 pb-6 pt-10 w-full text-16 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${className}`}
                placeholder=" "/>



            <label htmlFor={`${id}-input`}
                   className="pointer-events-none absolute flex gap-2 items-center text-input-dark text-14 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 left-1">
                {icon ? icon : null}
                {label}
            </label>
            {error ? <div
                className={'absolute px-2 text-red-500 text-12 mt-1 bg-red-50 top-full -mt-px w-full'}>{error.type}</div> : null}
        </div>

    )
});
Input.displayName = "Input"
export default Input