import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import Signature from "./Signature";
import Input from "./components/form/Input";
import Select from "./components/form/Select";
import {renderToString} from 'react-dom/server'


export interface IFormValues {
    company: string
    lastName: string;
    firstName: string;
    role: string,
    phone: string,
    email: string
}

function App() {

    const [brands, setBrands] = useState<any[]>([]);
    const [company, setCompany] = useState<any>(null);
    const [data, setData] = useState<any>({});
    const [html, setHTML] = useState<string>('');
    const [link, setLink] = useState<string>('');

    const {
        register,
        handleSubmit,
        control,
        resetField,
        setValue,
        getValues,
        watch,
        formState: {errors}
    } = useForm<IFormValues>({
        defaultValues: {
            firstName: '',
            lastName: '',
            role: '',
            phone: '',
            email: '',
        }
    });

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'company' && type === 'change') {
                setCompany(brands.find(brand => brand.id === value.company));
            } else if (type === 'change') {
                setData(value);
            }
        });

        setData(getValues());

        return () => subscription.unsubscribe();
    }, [watch, brands]);

    useEffect(() => {
        fetch('/brands.json')
            .then(raw => raw.json())
            .then(async (response) => {
                await setBrands(response);
                setCompany(response[0]);
                setValue('company', response[0].id);
            })
    }, []);

    const onSubmit = async (data: IFormValues) => {

        const html = renderToString(<Signature company={company} data={data}/>)
        const formData = new FormData();
        formData.append('html', html);
        formData.append('firstname', data.firstName);
        formData.append('lastname', data.lastName);

        const raw = await fetch('https://signature.gck.co/upload.php', {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            },
        });
        const json = await raw.json();

        console.log('json', json)
        if (json.file) {
            setLink(json.file);
        }
    }

    const signatureRef = useRef(null);

    const copy = () => {

        const elTable = signatureRef.current;

        let range;
        let sel;

// Ensure that range and selection are supported by the browsers
        if (document.createRange && window.getSelection) {

            range = document.createRange();
            sel = window.getSelection();
            // unselect any element in the page

            if (sel && elTable) {

                sel.removeAllRanges();

                try {
                    range.selectNodeContents(elTable);
                    sel.addRange(range);
                } catch (e) {
                    range.selectNode(elTable);
                    sel.addRange(range);
                }
            }

            document.execCommand('copy');
        }
        if (sel) {
            sel.removeAllRanges();
        }

        console.log('Element Copied! Paste it in a file')
    }


    return (
        <div className="container mx-auto my-10">
            <div className={'grid grid-cols-12'}>
                <div className={'col-span-12 md:col-span-6 flex flex-col mb-6'}>
                    <h2 className={'font-bold text-xl uppercase leading-none mb-2'}>Bienvenue sur le générateur de signature
                        mail</h2>
                    <p className={'leading-none'}>Remplissez les champs ci-dessous et suivez la procédure
                        d'installation</p>
                </div>
            </div>
            <div className={'grid grid-cols-12 gap-4'}>

                <form className={'col-span-12 md:col-span-6 flex flex-col gap-4'} onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className={'flex flex-col'}>
                        <span className={'text-input-dark text-14'}>Selectionnez votre société :</span>
                        <Select {...register('company', {required: true})} >
                            {brands.map(brand => (<option key={brand.id} value={brand.id}>{brand.id}</option>))}
                        </Select>
                    </fieldset>
                    <fieldset className={'flex flex-col'}>
                        <Input {...register('firstName', {required: true})} label={'Prénom'}/>
                        {errors.firstName && <small className={'text-red-500'}>Ce champ est requis</small>}
                    </fieldset>
                    <fieldset>
                        <Input {...register('lastName', {required: true})} label={'Nom'}/>
                        {errors.lastName && <small className={'text-red-500'}>Ce champ est requis</small>}
                    </fieldset>
                    <fieldset>
                        <Input {...register('role', {required: false})} label={'Titre'}/>
                        {errors.role && <small className={'text-red-500'}>Ce champ est requis</small>}
                    </fieldset>

                    <fieldset className={'flex flex-col'}>
                        <Input className={''} {...register('phone', {required: false})} label={'Téléphone'}/>
                        <small className={'text-gray-400'}>Respectez la mise en forme suivante : +33 6 00 00 00 00</small>
                        {errors.phone && <small className={'text-red-500'}>Ce champ est requis</small>}
                    </fieldset>
                    <fieldset>
                        <Input {...register('email', {required: true})} label={'Email'}/>
                        {errors.email && <small className={'text-red-500'}>Ce champ est requis</small>}
                    </fieldset>
                    <div className={'flex  gap-4'}>
                        <button className={'px-6 py-2 bg-primary rounded-sm text-white'} type={'submit'}>Générer
                        </button>
                        {link && <a target={'_blank'} href={`https://signature.gck.co${link}`}
                                    className={'px-6 py-2 text-center rounded-sm text-black border-2'}>Afficher la
                            signature</a>}
                    </div>
                    {link && <><h3 className={'font-bold text-xl uppercase'}>Procédure d'installation</h3><p className={''}>1. Cliquez sur
                        "Afficher la signature"</p><p>2. Sélectionnez l'integralité de la page avec Ctrl+A sur PC ou ⌘+A sur
                        Mac</p><p>3. Copiez la sélection Ctrl+C sur PC et ⌘+C sur Mac</p><p>4. Collez ensuite le contenu
                        dans votre éditeur de signature</p></>}

                    {link && <p>
                        <b>Si vous rencontrez des difficultés</b> contactez le service communication :<br />
                        Coralie IMARE - GREEN CORP KONNECTION<br />
                        coralie.imare@gck.co
                    </p>}
                </form>

                <div
                    className={'select-none pointer-events-none col-span-12 md:col-span-6 flex items-center flex flex-col items-center justify-center'}>
                    <div className={'block'}>
                        <Signature ref={signatureRef} company={company} data={data}></Signature>
                    </div>
                    {html && <textarea rows={10} cols={50} value={html}
                                       className={'w-full mt-10 p-4 text-[14px] border bg-gray-50'}/>}
                </div>
            </div>
        </div>
    );
}

export default App;
