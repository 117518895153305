import React, {forwardRef} from "react";
import Radio from "./Radio";

interface SelectProps {
    children?: React.ReactNode,
    onChange?: React.FormEventHandler<HTMLSelectElement>,
    onBlur?: React.FormEventHandler<HTMLSelectElement>,
    defaultValue?: string,
    required?: boolean,
    value?: string,
    name?: string,
    checked?: boolean,
    label?: string,
    onClick?: React.MouseEventHandler<HTMLSelectElement>
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(({
                                                               children,
                                                               defaultValue,
                                                               required,
                                                               onBlur,
                                                               checked,
                                                               onChange,
                                                               name,
                                                               label,
                                                               onClick,
                                                               value
                                                           }, ref) => {


    return (
        <select
            ref={ref}
            name={`${name}`}
            onChange={onChange}
            required={required}
            onBlur={onBlur}
            value={value}
            defaultValue={''}
            className="text-fakeBlack rounded-md border border-input-dark bg-input h-[42px] block px-2.5 w-full text-16  focus:outline-none focus:ring-0 focus:border-blue-600">
            <option value={''} disabled>{label}</option>
            {children}
        </select>

    )
});
Select.displayName = "Select"
export default Select

