import {IFormValues} from "./App";
import {forwardRef, memo} from "react";

interface SignatureProps {
    data: IFormValues,
    company: any
}

const Signature = forwardRef<HTMLHtmlElement, SignatureProps>(({data, company}: SignatureProps, ref) => {

    const capitalize = (value: string) => {
        return value;
        //return value.charAt(0).toUpperCase() + value.slice(1);
    }

    return (
        <>

            <style>
                @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
            </style>

            <table width={400} cellSpacing="0" cellPadding="0"
                   style={{fontFamily: "Montserrat, sans-serif"}}>
                <tbody>
                <tr>
                    <td width={160} style={{textAlign: 'center', padding: '10px 0'}}>
                        <img
                            width={150}
                            style={{
                                display: 'inline-block',
                                marginBottom: '10px'
                            }}
                            src={`https://signature.gck.co/logo.php?logo=${company?.id}`}/>
                        <div>
                            {(company?.links && company?.links.length) &&
                                company?.links.map((social: any) => {
                                    return (
                                        <a key={social.url}
                                           href={social.url}
                                           style={{
                                               display: 'inline-block',
                                               width: '30px',
                                               margin: 0,
                                               padding: 0
                                           }}>
                                            <img height={21} style={{display: 'block', margin: 0, padding: 0}}
                                                 src={`https://signature.gck.co/companies/${company?.id}/${social.type}.png`}/>
                                        </a>
                                    )
                                })
                            }
                        </div>

                    </td>
                    <td style={{borderLeft: `1px solid ${company?.colors.primary}`, paddingLeft: '20px'}}>
                        <p style={{
                            margin: '2px 0',
                            fontSize: '13px',
                            textAlign: 'left',
                            color: company?.colors.primary,
                            lineHeight: '13px',
                            marginBottom: '10px'
                        }}>{capitalize(data.firstName)} {capitalize(data.lastName)}
                            {(data?.role && data?.role !== '') && <span style={{
                            fontSize: '11px',
                            fontWeight: 'bold',
                            color: '#666666'
                        }}><br/>{data?.role}</span>}</p>
                        <p style={{fontSize: '11px', color: '#666666', lineHeight: '15px', margin: '2px 0',}}>
                                    <span style={{color: company?.colors.primary}}>
                                        <b>E : </b>
                                    <a href={`mailto:${data?.email}`}
                                       style={{
                                           display : 'inline-block',
                                           fontSize: '11px',
                                           color: '#666666',
                                           textDecoration: 'none'
                                       }}>{data?.email}</a>
                                </span>
                        </p>
                        {(data?.phone && data?.phone !== '') &&
                            <p style={{fontSize: '11px', color: '#666666', lineHeight: '15px', margin: '2px 0'}}>
                                <span
                                    style={{color: company?.colors.primary}}><b>T : </b></span>
                                <a href={`tel:${data?.phone}`}
                                   style={{

                                       fontSize: '11px',
                                       color: '#666666',
                                      textDecoration: 'none'
                                   }}>{data?.phone}</a>

                            </p>}
                        <p style={{fontSize: '11px', color: '#666666', lineHeight: '15px', margin: '2px 0'}}><span
                            style={{
                                color: company?.colors.primary,
                                fontSize: '11px'
                            }}><b>A : </b></span><span dangerouslySetInnerHTML={{ __html: company?.address.street }} /><br/>{company?.address.zipcode} {company?.address.city}, {company?.address.country}
                        </p>
                        <p style={{fontSize: '11px', color: '#666666', lineHeight: '15px', margin: '2px 0'}}><span
                            style={{color: company?.colors.primary, fontSize: '11px'}}><b>W : </b></span><a
                            href={company?.address.web} style={{
                            color: '#666666',
                            textDecoration: 'none'
                        }}>{company?.web}</a>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={{paddingTop : '10px'}}>
                        <img
                            width={400}
                            src={`https://signature.gck.co/signature.png?company=${company?.id}`}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    )
})

/*

 */

export default memo(Signature);